.containerPrincipal{
		display: flex;
		flex: 1;
		width: 100%; 
		flex-direction : column;
		align-items : flex-start;
		background-color: var(--shdow-blue-bg); 
		overflow-y: hidden
	}

.centerView{
		display: flex;
        justify-content:center;
        align-items:center
    }
