@font-face {
    font-family: 'Sheepman-Bold';
    src: url('../fonts/Sheepman-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sheepman-Light';
    src: url('../fonts/Sheepman-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'AlexBrush-Regular';
    src: url('../fonts/AlexBrush-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nephilm';
    src: url('../fonts/Nephilm.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600;700&display=swap');
/* cursive */

@import url('https://fonts.googleapis.com/css2?family=Fanwood+Text&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap');
/* serif */

@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;600;700&display=swap');
/* font-family: 'Roboto Condensed', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');
/* sans-serif */


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
/* font-family: 'Inter', sans-serif; */


@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
/* font-family: "Roboto Serif", serif; */