.divisorCarreira{
    display: flex;
    width: 100%;
    height: 10px;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 8vh;
}

.divisorCarreira div:nth-child(2){
    width: 10px;
    height: 100%;
    background-color: var(--white-color);
    border-radius: 50%;
}
.divisorCarreira div:nth-child(1), .divisorCarreira div:nth-child(3){
    width: 35%;
    height: 20%;
    background-color: var(--white-color);
} 

.carreira{
    padding-top: 5%;
    width: 94%;
    margin-left: 5%;
    overflow-x: hidden;
}

.carreira .topCarreira{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5em;
    color: var(--white-color);
    text-align: left;
    transition: all 1s ease-out;
    width: 100%;
}

.carreira .topCarreira .titleCarreira{
    font-size: 2em;
    font-weight: 700;
}

.carreira .topCarreira .subTitleCarreira{
    width: 50%;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
}

.carreira .titleCarreira-visible{
    animation: titleCarreira-visible 2s ease-in-out forwards;
}


/* LINHA TEMPORAL */

.linhaTemporal{
    display: grid;
    grid-template-columns: 30% 70%;
    overflow-x: hidden;
}


/* SELETOR LINHA TEMPORAL */
.carreira .linhaTemporalSeletor .buttonLinhaTemporal1,
.carreira .linhaTemporalSeletor .buttonLinhaTemporal2{
    width: 50px;
    aspect-ratio: 1/1;
    background-image: url('../imgs/carreira/tempArrow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    cursor: pointer;
}

.carreira .linhaTemporalSeletor > .buttonLinhaTemporal2{
    transform: rotate(180deg);
}


.carreira .linhaTemporalSeletor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    transform: translateX(-30%);
    height: fit-content;
    transition: opacity 0.5s ease-in-out;
  }
  
.carreira .linhaTemporalSeletor .timelineElement {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--white-color);
    width: 100%;
    cursor: pointer;
}

.carreira .linhaTemporalSeletor .line {
    width: 2px;
    height: 100%;
    background-color: var(--white-color); /* Set the color you desire */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }


.carreira .linhaTemporalSeletor .eventInfo{
    display: grid;
    grid-template-columns: 20% 10% 70%;
    justify-items: center;
    transform: translateX(25%);
    width: 100%;
  }

.carreira .linhaTemporalSeletor .eventInfo > div:not(.circle){
    margin: 15px 5px;
    width: 100%
}

.carreira .linhaTemporalSeletor .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--white-color); /* Set the color you desire */
    margin: 15px 0px;
}

.carreira .linhaTemporalSeletor .eventInfo .ano{
    text-align: right;
}

.carreira .linhaTemporalSeletor .eventInfo .titulo{
    text-align: left;
}


/* COMPONENT LINHA TEMPORAL */

.carreira .componentLinhaTemp{
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    transition: transform 1s ease-in-out;
}

.carreira .componentLinhaTemp.animaTransicao{
    animation: animaTransicao 1s alternate;
}

.carreira .componentLinhaTemp .titleCompLinha{
    color: var(--shdow-red-color);
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 20px;
}

.carreira .componentLinhaTemp .backCompLinha{
    width: 80px;
    aspect-ratio: 1/1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 20px auto;
}

.carreira .componentLinhaTemp .textCompLinha{
    color: var(--white-color);
    font-size: 1.3em;
    font-weight: 400;
}



/* MEDIA QUERY */

@media(max-width: 767px){

    .carreira{
        margin-left: 0;
        width: 100%;
        margin-bottom: 10%;
    }
    .carreira .topCarreira .subTitleCarreira {
        width: 95%;
        margin-left: 5%;
    }
    .carreira .topCarreira .titleCarreira {
        text-align: center;
    }

    /* LINHA TEMPORAL */

    .linhaTemporal{
        grid-template-columns: 100%;
    }
    .carreira .linhaTemporalSeletor {
        flex-direction: row;
        transform: translateX(0);
        width: 98%;
        padding: 0 1%;
    }
    .carreira .linhaTemporalSeletor .buttonLinhaTemporal1, 
    .carreira .linhaTemporalSeletor .buttonLinhaTemporal2 {
        min-width: 50px;
    }
    .carreira .linhaTemporalSeletor > .buttonLinhaTemporal1 {
        transform: rotate(-90deg) translateX(-15px);
    }
    .carreira .linhaTemporalSeletor > .buttonLinhaTemporal2 {
        transform: rotate(90deg) translateX(14px);
    }

    .carreira .linhaTemporalSeletor .line {
        width: 50%;
        height: 2px;
    }

    .carreira .linhaTemporalSeletor .eventInfo {
        display: grid;
        grid-template-columns: 100%;
        transform: translateX(0);
    }

    .carreira .linhaTemporalSeletor .eventInfo .ano{
        text-align: center;
    }

    .carreira .linhaTemporalSeletor .eventInfo > div:is(.imageTimeElement) {
        width: 50px;
        aspect-ratio: 1/1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .carreira .linhaTemporalSeletor .circle {
        display: flex;
        justify-content: center;
    }

    .carreira .linhaTemporalSeletor .line {
        width: 100%;
        height: 2px;
        background-color: var(--white-color); /* Set the color you desire */
        position: absolute;
        top: 50%;
        left: initial;
        transform: translateY(-50%);
    }
    
    
    /* COMPONENT LINHA TEMPORAL */
    .carreira .componentLinhaTemp.animaTransicao{
        animation: animaTransicao 0.5s alternate;
    }
}







@keyframes titleCarreira-visible {
    0%{
        color: var(--white-color);
    }
    100%{
        color: var(--yellowGold-color);
    }
}


@keyframes animaTransicao {
    0%{
        transform: translateX(300%);
    }
    100%{
        transform: translateX(0);
    }
}