.apresentInicial{
    display: grid;
    grid-template-columns: 35% 30% 35%;
    color: var(--white-color);  
    min-height: 80vh; 
    width: 100%;
}

.apresentInicial > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10%;
    transition: all 1s;
}

.apresentInicial .componentesNomeDesc{
    transform: translateY(100%) translateX(0);
}

.apresentInicial .nomePort{
    display: flex;
    flex-direction: column;
    font-family: 'Cardo', 'serif';
    font-weight: 700;
    font-size: 3em;
    margin-bottom: 2%;
    white-space: nowrap;
}
.apresentInicial .nomePort::after{
    content: '';
    width: 100%;
    height: 4px;
    margin-top: -2%;
    background-color: var(--yellowGold-color);
    border-radius: 15px;
}

.saibaMais{
    padding: 2% 5%;
    width: fit-content;
    border: 1px solid var(--white-color);
    box-shadow: 1px 1px 2px var(--white-color);
    cursor: pointer;
    margin-top: 15px;
}

.apresentInicial .descLeft{
    font-family: 'Source Sans 3', 'sans-serif';
    font-weight: 500;
    line-height: 1.2;
    white-space: nowrap;
}

.apresentInicial .descLeft > span{
    color: var(--shdow-red-color);
}

.apresentInicial .componentesFoto{
    scale: 0;
}
.apresentInicial .componentesFoto .fotoPortifolio{
    height: 80%;
    aspect-ratio: 1/1;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../imgs/photoPerfilStylized.svg');
    background-size: contain;
}


.apresentInicial .componentesServicos{
    padding: 0;
    transform: translateY(100%) translateX(0);
}

.apresentInicial  .servicosTitle{
    display: flex;
    flex-direction: column;
    font-family: 'Cardo', 'serif';
    font-weight: 400;
    font-size: 2em;
    margin-bottom: 2%;
    color: var(--yellowGold-color);
    width: fit-content;
}

.apresentInicial .servicosTitle::after{
    content: '';
    width: 100%;
    height: 1.5px;
    margin-top: -7%;
    background-color: var(--yellowGold-color);
    border-radius: 15px;
}

.servicosDesc{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}


@media(max-width: 767px){

    .apresentInicial{
        grid-template-columns: 100%;
        margin: 8% 0;
    }

    .apresentInicial > div {
        padding: 0 0% 0 5%;
    }
    .apresentInicial .componentesNomeDesc{
        width: 92%;
        transform: translateY(0) translateX(100%);
    }

    .apresentInicial .nomePort {
        font-size: 2.5em;
    }

    .apresentInicial .nomePort::after {
        width: 90%;
    }

    .apresentInicial .descLeft {
        white-space: pre-wrap;
    }

    .apresentInicial .componentesFoto{
        padding: 0;
    }

    .apresentInicial .componentesFoto .fotoPortifolio {
        width: 100%;
        height: auto;
        margin-top: 5%;        
    }

    .apresentInicial .servicosTitle {
        display: block;
        font-size: 1.3em;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500;
    }

    .apresentInicial .componentesServicos {
        margin-top: 5%;
        padding: 0 2% 0 5%;
        transform: translateX(-100%) translateY(0);
    }

    .servicosDesc {
        width: 90%;
        font-weight: 400;
    }
}