

:root {
    --white-color: #FFFFFF;
    --yellowGold-color: #EEB600;
    --shdow-blue-bg: #34353A;
    --shdow-gray-bg : #5d6069;
    --shwdow-grayHblt-color: #535560;
    --gray-rodape: #63686C;
    --shdow-red-color: #F22A4E;
    --shdow-brownRed-color: #7D5C5C;
    --shdow-brown-color: #382d00;
    --shwdow-grayHbltMid-color: #1e1e21;
}
	

[data-theme="dark"] {
    /*_____- Dark Mode -_______*/
    --bg-color: #575757;
    --shdow-blue-bg: #fffaf5;
}