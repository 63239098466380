.topMenu{
    display: flex;
    justify-content: space-between;
    position: fixed;
    top:0;
    /* background-color: var(--shdow-blue-bg); */
    width: 100%;
    height: 12vh;
    padding: 0 0; 
    z-index: 10000  !important;
    font-size: 1.2em;
}

.topMenu .leftMenu, .lateralMenu .leftMenu{
    width: 100%;
    padding: 1% 2% 1% 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topMenu .clientinfo, .lateralMenu .clientinfo{
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--yellowGold-color);
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.topMenu .logo, .lateralMenu .logo{
    height:100%;
    aspect-ratio: 1/1;
    width: fit-content;
    background-image: url('../imgs/menuLogo.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 10px;
    border-radius: 50%;
}


.topMenu .menuOptPrincipal{
    display: flex;
    margin-right: 15px;
    color: var(--white-color);
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;   
}

.topMenu .menuOptPrincipal > div{
    margin: auto 10px;
    cursor: pointer;
}


.topMenu .menuOptPrincipal .selectedOpt, .lateralMenu .menuOptPrincipal .selectedOpt{
    display: flex;
    flex-direction: column;
    color: var(--yellowGold-color);
}

.topMenu .menuOptPrincipal :not(.selectedOpt), .lateralMenu .menuOptPrincipal :not(.selectedOpt){
    margin-top: 10px;
}

.topMenu .menuOptPrincipal .selectedOpt::before{
    content: '';
    width: 100%;
    height: 3px;
    margin-bottom: 7px;
    background-color: var(--yellowGold-color);
}



@media(max-width: 767px){

    /* TOP MENU */

    .topMenu{
        height: 8vh;
        background-color: var(--shdow-gray-bg);
    }

    .topMenu .sideMenuBtn > div{
        width: 20px;
        height: 2.5px;
        border-radius: 15px;
        background-color: var(--white-color);
        margin: 4px 0 ;
    }

    /* MENU LATERAL */
    .lateralMenu{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: var(--shdow-gray-bg);
        z-index: 9999999 !important;
        color: var(--white-color);
        text-align: center;
        width: 95%;
        padding: 0;
    }

    .lateralMenuInicial{
        opacity: 0;
        width: 0;
        padding: 0;
        display: none;
    }

    .lateralMenu .clientinfo {
        font-size: 1.5em;
    }

    .lateralMenu .leftMenu{
        width: 94%;
        padding: 4% 3% 3% 3%;
        background-color: var(--shdow-gray-bg);
    }

    .lateralMenu .logo{
        width: 40px;
        aspect-ratio: 1/1;
    }

    .lateralMenu .menuCloseBtn{
        display: flex;
        /* position: absolute; */
        width: 28px;
        height: 3px;
        border-radius: 15px;
        background-color: #FFFFFF;
        rotate : -47deg;
        cursor: pointer;
    }
    
    .lateralMenu .menuCloseBtn::before{
        content: '';
        width: 28px;
        height: 3px;
        border-radius: 15px;
        background-color: #FFFFFF;
        rotate : -89deg;
        filter: drop-shadow(0px 2px 2px #00000088);
    }

    .lateralMenu .menuCloseBtn{
        right: 4%;
        top: 4%
    }

    .lateralMenu.mostraMenuLateral{
        animation: 1s showMenuMobile forwards 0s;
    }

    .lateralMenu.escondeMenuLateral{
        animation: 1s hideMenuMobile forwards 0s;
    }


    /* MENU OPTIONS */

    .lateralMenu .menuOptPrincipal{
        text-align: start;
        padding-left: 5%;
        margin: 5% 0;
        color: var(--white-color);
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500;  
    }

    .lateralMenu .menuOptPrincipal > div{
        width: fit-content;
        margin-bottom: 5%;
        font-size: 1.5em;
    }

    .lateralMenu .menuOptPrincipal .selectedOpt::after{
        content: '';
        width: 100%;
        height: 3px;
        background-color: var(--yellowGold-color);
    }

}

@keyframes showMenuMobile {
    0%{
        width: 0;
        padding: 0;
        opacity: 0;
    }
    20%{
        opacity: 1;
    }
    100%{
        opacity: 1;
        width: 100%;
    }
}


@keyframes hideMenuMobile {
    0%{
        width: 100%;
    }
    20%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        width: 0;
        padding: 0;
        display: none;
    }
}