.rodape {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--gray-rodape);
    overflow-x: hidden;
    min-height: 12vh;
    padding: 20px 0;
}

.rodape .fraseContext{
    -webkit-text-stroke: 0px #000000c0;
    background-color: transparent;
    color: var(--white-color);
    font-family: 'Inter', sans-serif;
    font-size: 1.5em;
    font-weight: 600;
    text-shadow: 0px 4px 4px #121212e2;
}

.rodape .fraseContext > span{
    color: var(--yellowGold-color);
}


.rodape .card{
    width: 80%;
    padding: 10px 10px;
    background-color: var(--white-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin: 20px 0;
}


.rodape .copyright{
    display: flex;
    align-items: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    color: var(--shdow-blue-bg);
}

.rodape .copyright .copyrightImg{
    width: 40px;
    aspect-ratio: 1/1;
    background-image: url('../imgs/rodape/copyright.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.rodape .socials{
    display: flex;
}

.rodape .socials > div {
    width: 40px;
    aspect-ratio: 1/1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 8px;
}
.rodape .socials .gitSoc{
    background-image: url('../imgs/rodape/github.png');
}

.rodape .socials .linkdInSoc{
    background-image: url('../imgs/rodape/linkdIn.png');
}

.rodape .socials .mailSoc{
    background-image: url('../imgs/rodape/email.png');
}

.rodape .socials .phoneSoc{
    background-image: url('../imgs/rodape/phone.png');
}

@media(max-width: 767px){
    .rodape .fraseContext{
        font-size: 1.2em;
        width: 90%;
        text-align: center;
    }

    .rodape .card{
        flex-direction: column;
    }

    .rodape .copyright{
        margin-bottom: 20px;
        text-align: center;
    }

    .rodape .copyright .copyrightImg{
        display: none;
    }

    .rodape .socials{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }
    
    .rodape .socials > div {
        margin-top: 9px auto 4px auto;
    }
}  