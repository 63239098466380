body{
    padding: 0;
    margin: 0;
}

.TayPage{
    position: relative;
    background: rgb(247,120,183);
    background: linear-gradient(0deg, #f778b7 0%, #f75a5a 100%);

    width: 100%;
    min-height: 100vh;
    min-height: 100svh;
    max-height: 100vh;
    max-height: 100svh;

    font-family: 'Sheepman-Bold', sans-serif;
    font-weight: 700;
    font-size: 1.3em;
    color: var(--white-color);

    overflow: hidden;
}

.TayPage .backgroundStars{
    position: absolute;
    width: 100%;
    min-height: 100vh;
    min-height: 100svh;
    max-height: 100vh;
    max-height: 100svh;
    pointer-events: none;
    z-index: 30;
}

.TayPage .textCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    min-height: 100vh;
    min-height: 100svh;
    max-height: 100vh;
    max-height: 100svh;
    z-index: 80;
    margin: 0 auto;
}


/* BUTTON INTRO */

.TayPage .textoIntroBtn{
    width: 130px;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5em;
    padding: 30px;
    border-radius: 50%;
    background-color: #FFFFFF;
    color: #000000;
    transition: 2s transform;
}

.TayPage .textoIntroBtn .animaIntroButton{
    
}


/* TEXT INTRO */

.TayPage .textoIntro{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.5em;
}

.TayPage .textoIntro .animaIntroTexto{
    animation: animaIntroTexto 3s ease forwards;
}


.TayPage .textoIntro .heartImg{
    width: 80px;
    aspect-ratio: 1/1;
    background-image: url('../imgs/heartIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    transition: 2s opacity;
}


@keyframes animaIntroTexto {
    0%{opacity: 0%}
    100%{opacity: 100%}
}

/* BUS Mid1 */

.TayPage .textoMid1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5em;
}

.TayPage .textoMid1 > div,
.TayPage .textoMid1  span{
    transition: 2s opacity, 2s transform;
}


.TayPage .textoMid1 .tayImage{
    width: 50vw;
    aspect-ratio: 1/1;
    background-image: url('../imgs/taySmile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 10px 0;
}




/* CLOCK Mid2 */

.TayPage .textoMid2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.TayPage .textoMid2 > div{
transition: 2s opacity, 2s transform;
}


.TayPage .textoMid2 .clockImage{
    width: 80px;
    aspect-ratio: 1/1;
    background-image: url('../imgs/clockIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    transition: 2s opacity, 2s transform;
}






/* CLOCK Mid3 */

.TayPage .textoMid3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    transition: 2s opacity, 2s transform;
}


.TayPage .textoMid3 .heartImg{
    position: relative;
    width: 50vw;
    aspect-ratio: 1/1;
    background-image: url('../imgs/heartIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 98%;
    
    z-index: 90;
}


.TayPage .textoMid3 .textBase{
    position: relative;
    z-index: 96;
}



.TayPage .textoMid3 .textBase.title{
    font-family: "AlexBrush-Regular", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.1em;
}

.TayPage .textoMid3 .textBase.sub{
    font-size: 2.1em;
}








/* CLOCK Mid4 */

.TayPage .textoMid4{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    transition: 2s opacity, 2s transform;
    font-size: 1.5em;
}



.TayPage .textoMid4 > div{
    transition: 2s opacity, 2s transform;
}






/* ESTRELAS */
.TayPage .star{
    position: absolute;
    top: var(--randomTop);
    left: var(--randomLeft);
    width: var(--randomSize);       
    height: var(--randomSize);
    background-color: #FFFFFF;
    border-radius: 50%; 
    -webkit-animation: showStar 3s ease infinite;
    -moz-animation: showStar 3s ease infinite;
    animation: showStar 3s ease infinite;
}



@-webkit-keyframes showStar {
    0%{opacity: 0%}
    50%{opacity: 100%}
    100%{opacity: 0%}
}

@-moz-keyframes showStar {
    0%{opacity: 0%}
    50%{opacity: 100%}
    100%{opacity: 0%}
}
@keyframes showStar {
    0%{opacity: 0%}
    50%{opacity: 100%}
    100%{opacity: 0%}
}
















.TayPage.css-backAnima {
    background: linear-gradient(0deg, #f75a5a, #f778b7, #7a78f7, #da5af7, #7889f7, #5af799);
    background-size: 1200% 1200%;

    -webkit-animation: AnimationName 23s ease infinite;
    -moz-animation: AnimationName 23s ease infinite;
    animation: AnimationName 23s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}