.divisorHablt{
    display: flex;
    width: 100%;
    height: 10px;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 8vh;
}
.divisorHablt div:nth-child(2){
    width: 10px;
    height: 100%;
    background-color: var(--white-color);
    border-radius: 50%;
}
.divisorHablt div:nth-child(1), .divisorHablt div:nth-child(3){
    width: 35%;
    height: 20%;
    background-color: var(--white-color);
} 

.habilidades{
    padding-top: 5%;
}

.habilidades .titleHablt{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 2.5em;
    color: var(--white-color);
    text-align: center;
    width: 100%;
    transition: transform 1s ease-out;
}


.habilidades .habltContainer{
    display: grid;
    grid-template-columns: 12% 12% 12% 12%;
    gap: 50px 10px;
    justify-content: space-evenly;
    width: 100%;
    margin: 5% 0%;
    transition: transform 1s ease-in-out, opacity 1.5s ease-in-out;
}

.habilidades .habltitem {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    aspect-ratio: 1/1;
    background-color: var(--shwdow-grayHbltMid-color);
    border-radius: 15px 0 15px 0 ;
    padding: 6%;
    cursor: pointer;
    border: 1px solid transparent; /* Necessário para bordas gradientes */
    position: relative; /* Isso garante que o pseudo-elemento seja posicionado em relação ao seu elemento pai */
}

.habilidades .habltitem::before {
    content: ''; /* Os pseudo-elementos requerem a propriedade content para funcionar */
    position: absolute; 
    top: 0; right: 0; bottom: 0; left: 0; 
    z-index: -1; 
    margin: -4px 0 -4px 0; /* Isso desloca o pseudo-elemento para fora do elemento pai por um pixel */
    
    /* Aplicando gradiente linear como imagem de fundo simulando cor da borda */
    background-image: linear-gradient(90deg, rgba(255,255,113,1) 0%, rgba(183,135,0,1) 100%);
    
    /* Arredondando os cantos do pseudo-elemento para combinar com os cantos arredondados do elemento pai */
    border-radius: inherit;
}



.habilidades .habltitem .textHablt{
    color: var(--white-color);
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1.5em;
}

.habilidades .habltitem .iconHablt{
    width: 60%;
    aspect-ratio: 1/1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.habilidades .habltitem.NodeJs .iconHablt{
    background-image: url('../imgs/nodeIcon.svg');
}

.habilidades .habltitem.React .iconHablt{
    background-image: url('../imgs/reactIcon.svg');
}

.habilidades .habltitem.ReactNative .iconHablt{
    background-image: url('../imgs/reactIcon.svg');
}

.habilidades .habltitem.Figma .iconHablt{
    background-image: url('../imgs/figmaIcon.svg');
}

.habilidades .habltitem.OracleSql .iconHablt{
    background-image: url('../imgs/sqlIcon.svg');
}

.habilidades .habltitem.PostgresSql .iconHablt{
    background-image: url('../imgs/postgreIcon.svg');
}

.habilidades .habltitem.Git .iconHablt{
    background-image: url('../imgs/gitIcon.svg');
}

.habilidades .habltitem.Python .iconHablt{
    background-image: url('../imgs/pythonIcon.svg');
}

.habilidades .habltitem.Linux  .iconHablt{
    background-image: url('../imgs/linuxIcon.svg');
}

.habilidades .habltitem.Java  .iconHablt{
    background-image: url('../imgs/javaIcon.svg');
}


@media(max-width: 767px){
    .habilidades .habltContainer{
        grid-template-columns: 30% 30%;
        gap: 20px 15px;
    }

    .habilidades .titleHablt{
        font-size: 1.8em;
        margin-bottom: 5%;
    }

    .habilidades .habltitem .textHablt{
        color: var(--white-color);
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 0.9em;
    }

    .habilidades .habltitem:nth-child(9) .iconHablt{
        background-image: url('../imgs/linuxIcon.svg');
    }
    
    .habilidades .habltitem:nth-child(10) .iconHablt{
        background-image: url('../imgs/javaIcon.svg');
    }
}