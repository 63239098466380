.projects .sideProject{
    width: 60%;
    aspect-ratio: 2/1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 1s;
    transform-style: preserve-3d;
    perspective: 1000px;
    margin: 0 auto;
}

.projects .sideProject .firstTit{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projects .sideProject .projItemType{
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-size: 1.5em;
    text-align: left;
}

.projects .sideProject .projItemTitle{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    font-size: 2.5em;
    text-align: left;
}



.projects .sideProject .projItemStatus{
    width: fit-content;
    margin: 0.4em auto 0.4em 0 ;
    padding: 4px 20px;
    border-radius: 5px;
    background-color: var(--projects-colorStatus);
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    color: var(--white-color);
    font-size: 1em;
}

.projects .sideProject .projItemDesc{
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 1.7em;
    text-align: justify;
    margin-top: 0.3em;
}

.projects .sideProject .projectVideo{
    position: absolute;
    top: 0;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}


@media(width >= 750px){

}