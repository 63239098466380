body{
    padding: 0;
    margin: 0;
}

.TayPageTempo{
    position: relative;
    background: #0B0110;
    background: linear-gradient(0deg, #0B0110 0%, #13042D 50%, #0B0110 100%);

    width: 100%;
    min-height: 100vh;
    min-height: 100svh;

    font-size: 1.3em;
    color: var(--white-color);

    overflow: hidden;
    text-align: center;


        display: flex;
        flex: 1 1;
        flex-direction: column;
        align-items: flex-start;

}

.TayPageTempo .titleTop{
    width: 65%;
    font-family: 'AlexBrush-Regular', sans-serif;
    font-weight: 500;
    font-size: 2em;
    text-align: center;
    margin: 0px auto 0 auto;
    color: #FF0066;

    transition: 1s;
    transform: translateY(300%);
    opacity: 0;
}

.TayPageTempo .titleTop.animaText{
    transform: translateY(0%);
    opacity: 1;
}


.TayPageTempo .title{
    font-family: 'Sheepman-Bold', sans-serif;
    font-weight: 700;
    width: 65%;
    margin: 30px auto 0 auto;

transition: 1s;
transform: translateY(300%);
opacity: 0;
}

.TayPageTempo .title.animaText{
transform: translateY(0%);
opacity: 1;
}


.TayPageTempo .subtext{
    width: 65%;
    font-family: 'Nephilm', sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 30px auto 0 auto;
}

.TayPageTempo .subtitle{
    width: 65%;
    font-family: 'AlexBrush-Regular', sans-serif;
    font-weight: 500;
    font-size: 1.8em;
    text-align: center;
    margin: 30px auto 0 auto;
    color: #FF0066;
}


.TayPageTempo .subtitle.conheceu{
    margin: 0px auto 0 auto;
    color: #62d3f7;
}

/* SLIDER */
.TayPageTempo .leftContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 522px;
    margin: 40px 0 20px 0;

    transition: 2s;
    transform: scale(0);
    /* opacity: 0; */
}
    
.TayPageTempo .leftContainer.animaText{
    transform: scale(1);
    opacity: 1;
}
    

.TayPageTempo .imageSlider{
    width: 255px;
    height: 100%;
    background: var(--imageTay) no-repeat center / cover;
    border: 2px solid #FFFFFF94;
    border-radius: 5px;
}

.TayPageTempo .buttonsSlider{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
    margin-top: 15px;
}










.TayPageTempo .temporizador{
    display: flex;
    width: 65%;
    justify-content: space-evenly;
    margin: 30px auto 50px auto
}


.TayPageTempo .temporizador .countBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.TayPageTempo .temporizador .countBox > div:nth-child(1){
    font-family: "Roboto Serif", serif;
    font-weight: 500;
}

.TayPageTempo .temporizador .countBox > div:nth-child(2){
    color: #FF0066;
    font-family: 'AlexBrush-Regular', sans-serif;
    font-weight: 500;
}

.TayPageTempo .temporizador.conheceu .countBox > div:nth-child(2){
    color: #62d3f7;
}



/* BUTTON INTRO */
.TayPageTempo.introBtn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.TayPageTempo .textoIntroBtn{
    width: 130px;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
    padding: 10px 50px;
    border-radius: 8px;
    background-color: #FFFFFF;
    transition: 2s transform;

    color: #FF0066;
    font-family: 'Nephilm', sans-serif;
}

.TayPageTempo .textoIntroBtn .animaIntroButton{
    
}




@media (max-width: 767px){
    .TayPageTempo .titleTop{
        width: 90%;
    }
    
    .TayPageTempo .title{
        width: 85%;
    }

    .TayPageTempo .subtext{
        width: 80%;
    }

    .TayPageTempo .temporizador{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 0 20px 20px 20px;
        width: 85%;
        justify-content: center;
    }


    .TayPageTempo .temporizador .countBox{
        margin-bottom:  30px;
        font-size: 1.5em;
    }

}